import React, { useState, useEffect } from "react"
import Layout from "../components/layout/layout"
import Header from "../components/header/header"
import Content from "../components/content/content"
import { Loading } from "../components/loading"
import { Form, Input, FormGroup, Button, Label, Col, Row } from "reactstrap"
import { fetch_post } from "../utils/fetch"
import { toast } from "react-toastify"
import { useAuth0 } from "@auth0/auth0-react"
import "../styles/contact.scss"
import { navigate } from "gatsby"
import { checkPassword } from "../utils/common"
import { css } from "glamor"

export default function ContactPage() {
  const { isAuthenticated, isLoading, user } = useAuth0()
  const [title, setTitle] = useState("")
  const [email, setEmail] = useState("")
  const [description, setDescription] = useState("")
  const [inputCode, setInputCode] = useState("")
  const [markerCode, setMarkerCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [formInvalid, setFormInvalid] = useState(true)
  const [inputCodeInvalid, setInputCodeInvalid] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  //Verification Code
  const createCode = () => {
    var canvasBtn = document.getElementById("mycanvas")
    var context = canvasBtn.getContext("2d")
    var codeNum = ""
    var codeLength = 4
    var random = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ]
    canvasBtn.width = 70
    canvasBtn.height = 36
    for (var i = 0; i < codeLength; i++) {
      var index = Math.floor(Math.random() * 36)
      codeNum += random[index]
    }
    const result = codeNum.toLowerCase()
    setMarkerCode(result)
    context.font = "20px Lato"
    context.fillStyle = "#495057"
    context.textAlign = "center"
    context.fillText(result, 35, 25)

    //Draw interference lines
    for (var l = 0; l <= 5; l++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      context.moveTo(Math.random() * 70, Math.random() * 36)
      context.lineTo(Math.random() * 70, Math.random() * 36)
      context.stroke()
    }

    //Draw interference points
    for (var p = 0; p <= 30; p++) {
      context.strokeStyle = randomColor()
      context.beginPath()
      var x = Math.random() * 70
      var y = Math.random() * 36
      context.moveTo(x, y)
      context.lineTo(x + 1, y + 1)
      context.stroke()
    }
  }

  // Random color
  const randomColor = () => {
    var r = Math.floor(Math.random() * 256)
    var g = Math.floor(Math.random() * 256)
    var b = Math.floor(Math.random() * 256)
    return "rgb(" + r + "," + g + "," + b + ")"
  }

  useEffect(() => {
    if (markerCode === "" && !pageLoading) {
      createCode()
    }
  })

  useEffect(() => {
    // Check whether it can be committed
    if (inputCode.length <= 0) {
      setInputCodeInvalid("success")
    } else if (String(inputCode) !== String(markerCode)) {
      setInputCodeInvalid("error")
    } else {
      setInputCodeInvalid("success")
    }

    if (
      String(inputCode) === String(markerCode) &&
      title !== "" &&
      email !== "" &&
      description !== ""
    ) {
      setFormInvalid(false)
    } else {
      setFormInvalid(true)
    }
  }, [title, email, description, inputCode, markerCode])

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        navigate("/inner-support/")
      } else {
        setPageLoading(false)
      }
    }
  }, [isLoading, isAuthenticated])

  const handleSubmit = event => {
    event.preventDefault()
    if (checkPassword(isAuthenticated, user)) return
    setLoading(true)
    fetch_post(
      `ui/support`,
      {
        title,
        email,
        description,
      },
      null
    )
      .then(() => {
        setShowModal(true)
      })
      .catch(error => {
        toast(`${error}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCloseModal = () => {
    setShowModal(null)
    setTitle("")
    setEmail("")
    setInputCode("")
    setMarkerCode("")
  }

  if (pageLoading) {
    return <Loading />
  }

  return (
    <Layout pageTitle="Request Support">
      <Header backgroundImage="/dashborad_header.png" className="contentPage" contentPage={true}>
        <h1 className="display-4 font-weight-bold">Request Support</h1>
      </Header>
      <Content>
        {showModal ? (
          <div style={{ fontSize: 16 }}>
            <p>Thank you for reaching out!</p>
            <p>A member of the CodeLinaro team will get in touch with you soon.</p>
            <Button className="btnBgColor" onClick={handleCloseModal}>
              Back
            </Button>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col">
                <div style={{ "margin-bottom": "30px" }}>
                  <div className="card border-success">
                    {/* <div className="card-header">
                <h3>CodeLinaro Users</h3>
              </div> */}
                    <div className="card-body">
                      <p>
                        If you have a CodeLinaro User Account, please login to make specific
                        prioritized requests.
                      </p>
                      <p>
                        <a className="btn mr-2 btnBgColor" href="/inner-support/">
                          Login
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <form>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Title</label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      value={title}
                      onChange={event => setTitle(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Description</label>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      value={description}
                      onChange={event => setDescription(event.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Email</label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={event => setEmail(event.target.value)}
                    />
                  </div>

                  <label for="exampleInputPassword1">Captcha</label>
                  <div className="form-group row">
                    <div className="col-sm-9">
                      <Input
                        type="text"
                        name="inputCode"
                        id="inputCode"
                        value={inputCode}
                        onChange={event => setInputCode(event.target.value)}
                      />
                    </div>
                    <div className="col-sm-3">
                      <div className="captchaBtn">
                        <canvas id="mycanvas" onClick={createCode}></canvas>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <strong>
                      <label style={{ color: "red" }}>
                        {inputCodeInvalid === "success" ? "" : "Wrong input"}
                        &nbsp;
                      </label>
                    </strong>
                    <br />

                    <Button
                      className="btnBgColor"
                      color={"primary"}
                      onClick={handleSubmit}
                      disabled={formInvalid}
                    >
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Sending...</span>
                        </span>
                      ) : (
                        "Submit Request"
                      )}
                    </Button>
                  </div>
                </form>
                <div className="row">&nbsp;</div>
              </div>
              <div className="col">
                <div>
                  <span {...css(styles.requestContent)}>
                    <img aria-hidden="true" {...css(styles.img)} src={"/support_user.svg"} />
                    <a href="/faq/61440014/">
                      <strong>Request password reset</strong>
                    </a>

                    <span {...css(styles.description)}>
                      <p style={{ marginBottom: 0 }}>
                        Please see these instructions on how to reset your password.
                      </p>
                    </span>
                  </span>
                </div>

                <div>
                  <span {...css(styles.requestContent)}>
                    <img aria-hidden="true" {...css(styles.img)} src={"/support_user.svg"} />
                    {/* <a href="/faq/81821897/"></a> */}
                    <a href="/reset_mfa">
                      <strong>Request MFA reset</strong>
                    </a>

                    <span {...css(styles.description)}>
                      <p style={{ marginBottom: 0 }}>
                        Follow these procedures for requesting your MFA enrollment on your account.
                      </p>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
    </Layout>
  )
}

const styles = {
  requestContent: {
    position: "relative",
    display: "block",
    padding: "15px 10px 15px 60px",
  },
  img: {
    position: "absolute",
    left: 0,
    width: "48px",
    height: "48px",
    padding: "5px",
  },
  supportContainer: {
    position: "relative",
    border: "0.75px solid #ebebeb",
    borderRadius: "12px",
    padding: "1rem",
  },
}
